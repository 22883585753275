import { createFileRoute } from '@tanstack/react-router';

import { HarvestImport } from '@/components/HarvestImport.tsx';
import { getHarvestAccessToken } from '@/utility/getHarvestAccessToken';

// Not using a lazy-loaded route to be able to use `beforeLoad` here
export const Route = createFileRoute('/import')({
  // Get the Harvest access token saved to LocalStorage before anything else
  beforeLoad: () => getHarvestAccessToken(),
  component: HarvestImport,
});
