import { ColumnDef } from '@tanstack/react-table';

import { DeleteExternalTimeEntryButton } from '@/components/DeleteExternalTimeEntryButton.tsx';
import { EditExternalTimeEntryButton } from '@/components/EditExternalTimeEntryButton.tsx';
import { ExternalTimeSheetSource } from '@/types/ExternalTimeSheetMapping';
import { Project } from '@/types/Project.ts';
import { ExternalTimeSheetEntry } from '@/types/TimeSheetEntry.ts';

export function getExternalTimeEntryColumns(
  deleteRow: (externalId: string) => void,
  externalTimeSheetSource: ExternalTimeSheetSource,
  onMappingSave: (externalTimeEntryId: string) => void,
  projects: Project[],
  saveTimeEntry: (timeEntry: ExternalTimeSheetEntry) => void
): ColumnDef<ExternalTimeSheetEntry>[] {
  return [
    {
      accessorKey: 'projectName',
      header: 'Project',
    },
    {
      accessorKey: 'taskName',
      header: 'Task',
    },
    {
      accessorKey: 'categoryName',
      header: 'Category',
    },
    {
      accessorKey: 'entryDate',
      header: 'Date',
    },
    {
      accessorKey: 'hours',
      header: 'Hours',
    },
    {
      accessorKey: 'description',
      header: 'Description',
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap gap-1">
            <EditExternalTimeEntryButton
              externalTimeSheetEntry={row.original}
              externalTimeSheetSource={externalTimeSheetSource}
              onMappingSave={onMappingSave}
              projects={projects}
              saveTimeEntry={saveTimeEntry}
            />
            <DeleteExternalTimeEntryButton
              deleteEntry={deleteRow}
              externalId={row.original.externalId}
            />
          </div>
        );
      },
    },
  ];
}
