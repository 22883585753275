import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { loginRequest, msalConfig } from '@/config/msal';

import App from './App';

// Initialize MSAL
export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        loadingComponent={() => <div>loading...</div>}
      >
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </StrictMode>
);
