import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export enum QueryKeys {
  CalendarEvents = 'calendarEvents',
  ExternalTimeSheetMappings = 'externalTimeSheetMappings',
  HarvestTimeEntries = 'harvestTimeEntries',
  TimeSheetPayload = 'timeSheetPayload',
}
