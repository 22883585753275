import { createFileRoute, Navigate } from '@tanstack/react-router';
import { z } from 'zod';

import { useToast } from '@/components/ui/use-toast';

function ErrorComponent() {
  const { toast } = useToast();

  toast({
    title: '⚠️ Failed to connect to Harvest',
    variant: 'destructive',
  });

  return <Navigate to="/" />;
}

// Not making this route lazy because the validateSearch option wasn't available for createLazyFileRoute
export const Route = createFileRoute('/callback/harvest')({
  component: Harvest,
  errorComponent: ErrorComponent,
  validateSearch: z.object({
    access_token: z.string(),
    expires_in: z.number(), // seconds
    scope: z.string(),
    token_type: z.string(),
  }),
});

function Harvest() {
  const { toast } = useToast();

  // Grab access token from the query params that harvest attaches when redirecting users back to our callback here
  const { access_token, expires_in, scope, token_type } = Route.useSearch();

  // Scope is in the format: `harvest:7654321`
  const harvestAccount = scope.split(':')[1];

  console.debug(`Received access token for the Harvest account: ${harvestAccount}`);

  // Calculate the expiration time for the access token
  // Getting the epoch time in milliseconds first
  const expirationTimeEpoch = Date.now() + expires_in * 1000;
  console.debug(`The access token will expire at: ${new Date(expirationTimeEpoch).toISOString()}`);

  // Save the access token to local storage
  localStorage.setItem(
    'harvestToken',
    JSON.stringify({ access_token, expiration_time: expirationTimeEpoch, scope, token_type })
  );

  toast({
    title: '🔒 Authenticated with Harvest',
  });

  return <Navigate to="/import" />;
}
