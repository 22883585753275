import { HarvestTimeEntryResponse } from '@/types/HarvestTimeEntry.ts';
import { queryHarvestApi } from '@/utility/queryHarvestApi.ts';

export type GetHarvestTimeEntriesProps = {
  from: Date;
  to: Date;
};

export const getHarvestTimeEntries = ({ from, to }: GetHarvestTimeEntriesProps) => {
  // For documentation, see https://help.getharvest.com/api-v2/timesheets-api/timesheets/time-entries/
  return queryHarvestApi<HarvestTimeEntryResponse>(
    `time_entries?from=${from.toISOString()}&to=${to.toISOString()}`
  );
};
