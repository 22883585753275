import { TimeSheetEntry } from '@/types/TimeSheetEntry';
import { queryApi } from '@/utility/queryApi';

export type AddTimeSheetEntriesV2Props = {
  timeSheetEntries: TimeSheetEntry[];
};

export const addTimeSheetEntriesV2 = ({ timeSheetEntries }: AddTimeSheetEntriesV2Props) =>
  queryApi<TimeSheetEntry[]>({
    body: JSON.stringify(timeSheetEntries),
    method: 'POST',
    path: '/api/time-sheet/entriesv2',
  });
