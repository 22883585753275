import { Side } from '@radix-ui/react-popper';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Project } from '@/types/Project';
import { ProjectTaskCategoryFormValues } from '@/types/TimeSheetEntryFormValues';

import ScrollableContainer from './ScrollableContainer';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select';

type ProjectTaskAndCategoryFormFieldsProps = {
  defaultStintId: string | undefined;
  projects: Project[];
  submitPending: boolean;
  selectSide?: Side;
};

export const ProjectTaskAndCategoryFormFields = ({
  defaultStintId,
  projects,
  submitPending,
  selectSide,
}: ProjectTaskAndCategoryFormFieldsProps) => {
  const [selectedStintId, setSelectedStintId] = useState(defaultStintId);
  const { control, resetField } = useFormContext<ProjectTaskCategoryFormValues>();

  return (
    <>
      {/* Watch this PR to fix SelectValue and possibly add loading state that way https://github.com/shadcn-ui/ui/issues/1529 */}
      <FormField
        control={control}
        name="project"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Project</FormLabel>
            <Select
              onValueChange={(value) => {
                setSelectedStintId(value);
                resetField('task', { defaultValue: null });
                resetField('category', { defaultValue: null });
                return field.onChange(value);
              }}
              value={field.value ?? ''}
              disabled={submitPending}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select a project" />
                </SelectTrigger>
              </FormControl>
              <SelectContent side={selectSide}>
                {/* ScrollableContainer is possibly not needed when Radix fixes their Select https://github.com/radix-ui/primitives/issues/1980 */}
                <ScrollableContainer>
                  {projects.map((project) => (
                    <SelectItem key={project.name} value={project.stintId}>
                      {project.name}
                    </SelectItem>
                  ))}
                </ScrollableContainer>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="task"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Task</FormLabel>
            <Select
              onValueChange={field.onChange}
              value={field.value ?? ''}
              disabled={submitPending}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder={submitPending ? 'Loading...' : 'Select a task'} />
                </SelectTrigger>
              </FormControl>
              <SelectContent side={selectSide}>
                <ScrollableContainer>
                  {projects
                    .find((p) => p.stintId === selectedStintId)
                    ?.tasks.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                    .map((task) => (
                      <SelectItem key={task.displayName} value={task.id}>
                        {task.displayName}
                      </SelectItem>
                    ))}
                </ScrollableContainer>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      {!!projects.find((p) => p.stintId === selectedStintId)?.categories.length && (
        <FormField
          control={control}
          name="category"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Category</FormLabel>
              <Select
                onValueChange={field.onChange}
                value={field.value ?? ''}
                disabled={submitPending}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent side={selectSide}>
                  <ScrollableContainer>
                    {projects
                      .find((p) => p.stintId === selectedStintId)
                      ?.categories.map((category) => (
                        <SelectItem key={category.name} value={category.id}>
                          {category.name}
                        </SelectItem>
                      ))}
                  </ScrollableContainer>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </>
  );
};
