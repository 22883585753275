import { ExternalTimeSheetMapping } from '@/types/ExternalTimeSheetMapping';
import { queryApi } from '@/utility/queryApi';

export type EditExternalTimeSheetMappingProps = {
  externalTimeSheetMapping: ExternalTimeSheetMapping;
};

export const editExternalTimeSheetMapping = ({
  externalTimeSheetMapping,
}: EditExternalTimeSheetMappingProps) =>
  queryApi<ExternalTimeSheetMapping>({
    body: JSON.stringify(externalTimeSheetMapping),
    method: 'PUT',
    path: `/api/external-time-sheet-mappings/${externalTimeSheetMapping.id}`,
  });
