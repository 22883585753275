import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import React, { Suspense } from 'react';

import { Layout } from '@/components/layout/Layout';
import { Toaster } from '@/components/ui/toaster';

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()({
  component: Root,
});

// Lazy load the devtools in development
const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

function Root() {
  return (
    <>
      <Layout>
        <Outlet />
        <Toaster />
      </Layout>
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
    </>
  );
}
