import { getHarvestAccessToken } from './getHarvestAccessToken';

export const queryHarvestApi = async <T>(path: string): Promise<T | undefined> => {
  const accessToken = await getHarvestAccessToken();
  const apiBaseUrl = 'https://api.harvestapp.com/v2/';

  const httpHeaders: HeadersInit = {
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  // Hit the Harvest API
  const response = await fetch(`${apiBaseUrl}${path}`, {
    headers: httpHeaders,
    method: 'GET',
  });

  if (!response.ok) {
    const defaultErrorMessage = `Unable to query ${apiBaseUrl}${path}.`;
    const errorResponse = await response.json();
    throw new Error(
      `${errorResponse.Detail || defaultErrorMessage} Error code: ${response.status}`
    );
  }

  return response.json();
};
