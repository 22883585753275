import { ExternalTimeSheetMapping } from '@/types/ExternalTimeSheetMapping';
import { queryApi } from '@/utility/queryApi';

export type AddExternalTimeSheetMappingProps = {
  externalTimeSheetMapping: ExternalTimeSheetMapping;
};

export const addExternalTimeSheetMapping = ({
  externalTimeSheetMapping,
}: AddExternalTimeSheetMappingProps) =>
  queryApi<ExternalTimeSheetMapping>({
    body: JSON.stringify(externalTimeSheetMapping),
    method: 'POST',
    path: '/api/external-time-sheet-mappings',
  });
