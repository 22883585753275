export const getHarvestAccessToken = async (): Promise<string | undefined> => {
  const clientId = import.meta.env.VITE_HARVEST_OAUTH_CLIENT_ID;

  // The endpoint for Implicit Grant flow for client-side authorization
  // https://help.getharvest.com/api-v2/authentication-api/authentication/authentication/#for-client-side-applications
  const harvestEndpoint = `https://id.getharvest.com/oauth2/authorize?client_id=${clientId}&response_type=token`;

  let accessToken: string | undefined;

  try {
    // Check LocalStorage for the access token first
    const token = localStorage.getItem('harvestToken');

    // Try to parse the json for the access token
    if (token) {
      accessToken = JSON.parse(token).access_token;
    } else {
      throw new Error('The Harvest token is missing from LocalStorage');
    }

    // Check if the access token is expired, or will expire in the next hour
    if (accessToken) {
      const expirationTime = JSON.parse(token).expiration_time;
      const now = Date.now();
      const oneHour = 60 * 60 * 1000;

      if (expirationTime < now) {
        throw new Error('The Harvest access token has expired');
      }

      if (expirationTime - now < oneHour) {
        throw new Error('The Harvest access token will expire in the next hour');
      }
    }

    console.debug('Retrieved Harvest access token from LocalStorage');

    if (!accessToken) {
      throw new Error('The Harvest access token is missing for an unknown reason');
    }

    return accessToken;
  } catch (error) {
    console.debug('Failed to retrieve Harvest access token from LocalStorage:', error);

    console.debug('Redirecting user to Harvest for authorization');

    // Redirect user to Harvest's authorization page
    window.location.assign(harvestEndpoint);
  }

  console.error('Unable to get a Harvest access token for an unknown reason');

  return undefined;
};
